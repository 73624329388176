/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// import { useSearchableTable } from '../../hooks/useSearchableTable';

import {Link, useParams} from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import TabPane from 'antd/es/tabs/TabPane';
import {
  Badge,
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Space,
  Tabs,
  notification,
} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle, faPlus} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import * as subscriptionsService from '../../services/subscriptions';
import * as eventsService from '../../services/events';

import {pointNamesByType} from '../../helpers/params';

import stylesLayout from '../../common/layout.module.scss';
import Loading from '../../components/Loading';
import EventsList from '../../components/EventsList';
import NotesListModule from '../NotesListModule';
import SubscriptionItemsTable from '../../components/SubscriptionItemsTable';

import RenewalItem from '../../components/RenewalItem';
import SubscriptionDocumentsTable from '../../components/SubscriptionDocumentsTable';
import {getDeviceByTypeQROrRef} from '../../services/devices';
import {formatMoneyFromFleet} from '../../helpers/utils';

const SubscriptionModule = ({user, selectedFleet}) => {
  const {id: idParam} = useParams();
  const [loading, setLoading] = useState(false);
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [qRNewItem, setQRNewItem] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [newPlan, setNewPlan] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);
  const [showChangePlan, setShowChangePlan] = useState(false);
  const [showChangeEndDate, setShowChangeEndDate] = useState(false);
  const [saving, setSaving] = useState(false);

  const calculateBreakdown = ({itemType, plan, qty}) => {
    const unitPrice =
      selectedFleet.cost?.grin4u?.plans[itemType]?.find((x) => x.value === plan)
        ?.price || 0;
    const subtotal = unitPrice * qty;
    const discountReason = '';
    const referralDiscount = 0;
    const fieldDiscount = 0;
    const discount = Math.min(Math.max(fieldDiscount, 0), subtotal);
    const total = Math.max(subtotal - discount, 0);
    return {
      qty,
      unitPrice,
      subtotal,
      referralDiscount,
      discount,
      discountReason,
      total,
    };
  };

  const loadSubscription = async (id) => {
    setLoading(true);
    const [rspSubscription, rspEvents] = await Promise.all([
      subscriptionsService.get(id),
      eventsService.getForItem('subscription', id),
    ]);

    if (rspSubscription?.status) {
      if (rspEvents?.status) {
        rspSubscription.data.events = rspEvents.data;
      } else {
        rspSubscription.data.events = [];
      }
      setSubscription(rspSubscription.data);
      setNewEndDate(moment.utc(rspSubscription.data.end_at));
    }

    setLoading(false);
  };

  const onUpdateRenewal = () => {
    loadSubscription(idParam);
  };
  const openAddItemForm = () => {
    setQRNewItem('');
    setShowAddItemForm(true);
  };

  const setSubscriptionStatus = async (subscriptionId, status) => {
    notification.info({
      message: 'Cambiando estado de la suscripción...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await subscriptionsService.setStatus(subscriptionId, status);
    if (rsp?.status) {
      notification.success({
        message: 'Estado de la suscripción cambiado con éxito.',
        placement: 'top',
        key: 'result',
      });
      loadSubscription(idParam);
    } else {
      notification.error({
        message: 'Error al cambiar el estado de la suscripción.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const setSubscriptionQty = async (subscriptionId, qty) => {
    notification.info({
      message: 'Cambiando cantidad de patines de la suscripción...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await subscriptionsService.setQty(subscriptionId, qty);
    if (rsp?.status) {
      notification.success({
        message: 'Cantidad de patines de la suscripción cambiado con éxito.',
        placement: 'top',
        key: 'result',
      });
      loadSubscription(idParam);
    } else {
      notification.error({
        message: 'Error al cambiar la cantidad de patines de la suscripción.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const setItemStatus = async (itemId, status, data = {}) => {
    notification.info({
      message: 'Cambiando estado del item...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await subscriptionsService.setItemStatus(itemId, status, data);
    if (rsp?.status) {
      notification.success({
        message: 'Estado del item cambiado con éxito.',
        placement: 'top',
        key: 'result',
      });
      loadSubscription(idParam);
    } else {
      notification.error({
        message: 'Error al cambiar el estado del item.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const addItem = async () => {
    notification.info({
      message: 'Agregando item...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await getDeviceByTypeQROrRef({
      type: 'scooter',
      code: qRNewItem,
    });

    if (rsp?.status) {
      if (
        rsp.data.fleet_id === subscription.fleet_id &&
        ['available', 'in_warehouse'].includes(rsp.data.status)
      ) {
        const device = rsp.data;

        const rsp2 = await subscriptionsService.addItem(subscription.id, {
          item_type: 'device',
          item_id: device.id,
        });
        if (rsp2?.status) {
          notification.success({
            message: 'Item agregado con éxito.',
            placement: 'top',
            key: 'result',
          });
          loadSubscription(idParam);
        } else {
          notification.error({
            message: 'Error al agregar item.',
            placement: 'top',
            key: 'result',
          });
        }
      } else {
        notification.error({
          message: `Este patín no puede ser entregado. Estado: ${rsp.data.status_tr}`,
          placement: 'top',
          key: 'result',
        });
      }
    } else {
      notification.error({
        message: 'Este patín no puede ser entregado.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  console.log('subscription.items', subscription?.items);
  const updatePlan = async () => {
    const plans = selectedFleet.cost?.grin4u?.plans[subscription.item_type];
    const plan = plans.find((x) => x.value === newPlan);
    const breakdown = calculateBreakdown({
      itemType: subscription.item_type,
      qty: subscription.qty,
      plan: newPlan,
    });
    console.log('plans', plan, newPlan);
    if (!plan) {
      return;
    }

    notification.info({
      message: 'Cambiando plan...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    setSaving(true);
    const rsp = await subscriptionsService.changePlan(subscription.id, {
      plan,
      breakdown,
    });
    if (rsp?.status) {
      notification.success({
        message: 'Plan cambiado con éxito.',
        placement: 'top',
        key: 'result',
      });
      setShowChangePlan(false);
      loadSubscription(subscription.id);
    } else {
      notification.error({
        message: 'Error al cambiar el plan.',
        placement: 'top',
        key: 'result',
      });
    }
    setSaving(false);
  };
  const updateEndDate = async () => {
    notification.info({
      message: 'Cambiando fecha de fin...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    setSaving(true);
    console.log(
      'SAVING',
      newEndDate,
      moment(newEndDate),
      dayjs(newEndDate).format('YYYY-MM-DD')
    );
    const rsp = await subscriptionsService.changeEndDate(
      subscription.id,
      dayjs(newEndDate).format('YYYY-MM-DD')
    );
    if (rsp?.status) {
      notification.success({
        message: 'Fecha de fin cambiada con éxito.',
        placement: 'top',
        key: 'result',
      });
      setShowChangeEndDate(false);
      loadSubscription(subscription.id);
    } else {
      notification.error({
        message: 'Error al cambiar la fecha de fin.',
        placement: 'top',
        key: 'result',
      });
    }
    setSaving(false);
  };

  const onItemAction = async (id, action, data = {}) => {
    console.log('perform', action, 'on id ', id);
    if (action === 'mark_require_change') {
      Modal.confirm({
        width: 500,
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>¿Estás seguro de marcar este item como Requiere cambio?</div>
        ),
        okText: 'Si',
        okButtonProps: {danger: true},
        cancelText: 'No',
        onOk() {
          setItemStatus(id, 'REQUIRE_CHANGE', data);
          console.log('OK perform', action, 'on id ', id, data);
        },
        onCancel() {
          console.log('CANCEL perform', action, 'on id ', id);
        },
      });
    }
    if (action === 'mark_returned') {
      Modal.confirm({
        width: 500,
        icon: <ExclamationCircleOutlined />,
        content: <div>¿Estás seguro de marcar este item como Retornado?</div>,
        okText: 'Si',
        okButtonProps: {danger: true},
        cancelText: 'No',
        onOk() {
          setItemStatus(id, 'RETURNED');
          console.log('OK perform', action, 'on id ', id);
        },
        onCancel() {
          console.log('CANCEL perform', action, 'on id ', id);
        },
      });
    }
    if (action === 'mark_stolen') {
      Modal.confirm({
        width: 500,
        icon: <ExclamationCircleOutlined />,
        content: <div>¿Estás seguro de marcar este item como ROBADO?</div>,
        okText: 'Si',
        okButtonProps: {danger: true},
        cancelText: 'No',
        onOk() {
          setItemStatus(id, 'STOLEN');
          console.log('OK perform', action, 'on id ', id);
        },
        onCancel() {
          console.log('CANCEL perform', action, 'on id ', id);
        },
      });
    }
    if (action === 'mark_assigned') {
      Modal.confirm({
        width: 500,
        icon: <ExclamationCircleOutlined />,
        content: <div>¿Estás seguro de volver este item a Asignado?</div>,
        okText: 'Si',
        okButtonProps: {danger: true},
        cancelText: 'No',
        onOk() {
          setItemStatus(id, 'ASSIGNED');
          console.log('OK perform', action, 'on id ', id);
        },
        onCancel() {
          console.log('CANCEL perform', action, 'on id ', id);
        },
      });
    }
  };

  useEffect(() => {
    loadSubscription(idParam);
  }, [idParam]);

  const assignedItems =
    subscription?.items?.filter((x) =>
      [
        'ASSIGNED',
        'REQUIRE_CHANGE',
        'REQUIRE_CHANGE_READY_FOR_SHIPPING',
      ].includes(x.status)
    ) ?? [];

  if (loading) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }
  if (!subscription) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>Suscripción no encontrada</div>
      </div>
    );
  }
  console.log('user?.can?', user?.can);
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.breadcrumb}>
        <Link to='/suscripciones'>Suscripciones</Link> &raquo;
      </div>

      <div className={stylesLayout.title}>Suscripción</div>
      <div className={stylesLayout.subtitle}>
        #{subscription.id} -{subscription.data?.plan?.name}
        <div className='text-xs'>
          Sync: {subscription.data?.__synced} (
          {moment
            .utc(subscription.data?.__synced_at)
            .local()
            .format('YYYY-MM-DD HH:mm')}
          )
        </div>
      </div>
      <div className={stylesLayout.tools}>
        {user?.can?.executive_grin4u &&
        ['NON_PAID', 'COMPLETE_PAYMENT', 'PAID', 'READY_FOR_SHIPPING'].includes(
          subscription.status
        ) ? (
          <span style={{marginLeft: 20}}>
            <Button
              onClick={() => setSubscriptionStatus(subscription.id, 'VOID')}
            >
              Anular suscripción
            </Button>
          </span>
        ) : null}
      </div>
      <div className={stylesLayout.content}>
        <div className={stylesLayout.desktop_2cols400}>
          <div>
            {moment.utc(subscription.end_at).local().format('YYYY-MM-DD') <
              moment().format('YYYY-MM-DD') && (
              <div className='rounded border border-yellow-200 bg-yellow-50 p-4 mb-4 flex gap-2 items-start'>
                <FontAwesomeIcon
                  className='text-yellow-600 mt-[3px]'
                  icon={faExclamationTriangle}
                />
                <div className='text-sm flex flex-col gap-1'>
                  <div className='font-bold text-yellow-600'>
                    {subscription.status_tr}
                  </div>
                  <div>
                    Fecha de fin:{' '}
                    {moment
                      .utc(subscription.end_at)
                      .local()
                      .format('YYYY-MM-DD')}
                  </div>
                </div>
              </div>
            )}
            <table className={stylesLayout.infotable}>
              <tr>
                <td width='25%'>Cliente</td>
                <td>
                  <Link to={`/cliente/${subscription.user.id}`}>
                    {subscription.user.name}
                  </Link>
                </td>
              </tr>
              <tr>
                <td width='25%'>Plan</td>
                <td>
                  <div>
                    {subscription.data.plan?.name}
                    {!['FINISHED', 'VOID'].includes(subscription.status) && (
                      <span style={{marginLeft: 20}}>
                        <Button
                          onClick={() => setShowChangePlan(true)}
                          disabled={saving}
                        >
                          Cambiar plan
                        </Button>
                      </span>
                    )}
                  </div>
                  {showChangePlan && (
                    <div style={{marginTop: 20}}>
                      <Select
                        style={{width: '100%'}}
                        placeholder='Selecciona un nuevo plan...'
                        onChange={(v) => {
                          setNewPlan(v);
                        }}
                        options={
                          subscription.item_type
                            ? selectedFleet.cost?.grin4u?.plans[
                                subscription.item_type
                              ].map((x) => ({
                                ...x,
                                label: (
                                  <>
                                    <Badge
                                      count={x.current ? 'Actual' : 'Anterior'}
                                      color={x.current ? '#090' : 'c30'}
                                      style={{marginRight: 5}}
                                    />
                                    {`${x.label} - ${formatMoneyFromFleet(x.price, selectedFleet)}`}
                                  </>
                                ),
                              }))
                            : []
                        }
                      />
                      <div style={{marginTop: 5}}>
                        <Button
                          type='primary'
                          onClick={updatePlan}
                          disabled={saving}
                        >
                          Cambiar
                        </Button>
                        <Button
                          type='link'
                          style={{color: '#f30'}}
                          onClick={() => setShowChangePlan(false)}
                          disabled={saving}
                        >
                          Cerrar
                        </Button>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td width='25%'>Cantidad de patines</td>
                <td>
                  {subscription.qty}
                  {assignedItems.length > 0 &&
                    assignedItems.length !== subscription.qty && (
                      <Button
                        style={{marginLeft: 10}}
                        onClick={() =>
                          setSubscriptionQty(
                            subscription.id,
                            assignedItems.length
                          )
                        }
                      >
                        Cambiar a {assignedItems.length}{' '}
                        {assignedItems.length !== 1 ? 'patines' : 'patín'}
                      </Button>
                    )}
                </td>
              </tr>
              <tr>
                <td width='25%'>Total</td>
                <td>
                  {subscription.fleet.cost?.currency_symbol}
                  {numeral(subscription.total).format(
                    subscription.fleet.cost?.currency_format
                  )}
                </td>
              </tr>
              <tr>
                <td width='25%'>Status</td>
                <td>
                  <div className='grid gap-8 grid-cols-[150px_1fr] items-start'>
                    <div>{subscription.status_tr}</div>
                    <div className='flex gap-2 flex-wrap'>
                      {(subscription.status !== 'ACTIVE' &&
                        [1, 8].includes(user?.id)) ||
                      (user?.can?.executive_grin4u &&
                        subscription.status === 'READY_FOR_SHIPPING' &&
                        subscription.items?.filter(
                          (x) => x.status === 'ASSIGNED'
                        ).length > 0) ? (
                        <span>
                          <Button
                            onClick={() =>
                              setSubscriptionStatus(subscription.id, 'ACTIVE')
                            }
                          >
                            Activa
                          </Button>
                        </span>
                      ) : null}

                      {user?.can?.executive_grin4u &&
                      [
                        'ACTIVE',
                        'MISAPPROPRIATION',
                        'RENEWAL_REQUIRED',
                      ].includes(subscription.status) ? (
                        <span>
                          <Button
                            onClick={() =>
                              setSubscriptionStatus(
                                subscription.id,
                                'PICKUP_REQUIRED'
                              )
                            }
                          >
                            Por recoger
                          </Button>
                        </span>
                      ) : null}

                      {user?.can?.executive_grin4u &&
                      ['PICKUP_REQUIRED', 'MISAPPROPRIATION'].includes(
                        subscription.status
                      ) ? (
                        <span>
                          <Button
                            onClick={() =>
                              setSubscriptionStatus(
                                subscription.id,
                                'RENEWAL_REQUIRED'
                              )
                            }
                          >
                            Pendiente de renovación
                          </Button>
                        </span>
                      ) : null}

                      {user?.can?.executive_grin4u &&
                      subscription.status === 'PICKUP_REQUIRED' ? (
                        <span>
                          <Button
                            onClick={() =>
                              setSubscriptionStatus(
                                subscription.id,
                                'MISAPPROPRIATION'
                              )
                            }
                          >
                            Apropiación ilícita
                          </Button>
                        </span>
                      ) : null}

                      {user?.can?.executive_grin4u &&
                      !['FINISHED', 'VOID'].includes(subscription.status) &&
                      subscription.items?.filter((x) => x.status === 'ASSIGNED')
                        .length === 0 ? (
                        <span>
                          <Button
                            onClick={() =>
                              setSubscriptionStatus(subscription.id, 'FINISHED')
                            }
                          >
                            Finalizada
                          </Button>
                        </span>
                      ) : null}

                      {user?.can?.executive_grin4u &&
                      [
                        'ACTIVE',
                        'MISAPPROPRIATION',
                        'RENEWAL_REQUIRED',
                        'PICKUP_REQUIRED',
                      ].includes(subscription.status) &&
                      subscription.items?.filter((x) => x.status === 'STOLEN')
                        .length > 0 ? (
                        <span>
                          <Button
                            onClick={() =>
                              setSubscriptionStatus(subscription.id, 'STOLEN')
                            }
                          >
                            Robada
                          </Button>
                        </span>
                      ) : null}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td width='25%'>Fecha de creación</td>
                <td>
                  {subscription.created_at
                    ? moment
                        .utc(subscription.created_at)
                        .local()
                        .format('YYYY-MM-DD HH:mm')
                    : '-'}
                </td>
              </tr>
              <tr>
                <td width='25%'>Fecha de actualización</td>
                <td>
                  {subscription.updated_at
                    ? moment
                        .utc(subscription.updated_at)
                        .local()
                        .format('YYYY-MM-DD HH:mm')
                    : '-'}
                </td>
              </tr>
              <tr>
                <td width='25%'>Fecha de último pago</td>
                <td>
                  {subscription.last_paid_at
                    ? moment
                        .utc(subscription.last_paid_at)
                        .local()
                        .format('YYYY-MM-DD HH:mm')
                    : '-'}
                </td>
              </tr>
              <tr>
                <td width='25%'>Fecha de comienzo</td>
                <td>
                  {subscription.start_at
                    ? moment
                        .utc(subscription.start_at)
                        .local()
                        .format('YYYY-MM-DD')
                    : '-'}
                </td>
              </tr>
              <tr>
                <td width='25%'>Fecha de fin</td>
                <td>
                  {!showChangeEndDate ? (
                    <div>
                      <span style={{marginRight: 20}}>
                        {subscription.end_at
                          ? moment
                              .utc(subscription.end_at)
                              .local()
                              .format('YYYY-MM-DD')
                          : '-'}
                      </span>
                      {user.can.executive_grin4u &&
                        subscription.end_at &&
                        ['ACTIVE', 'RENEWAL_REQUIRED'].includes(
                          subscription.status
                        ) && (
                          <Button
                            onClick={() => setShowChangeEndDate(true)}
                            disabled={saving}
                          >
                            Cambiar fecha de finalización
                          </Button>
                        )}
                    </div>
                  ) : (
                    <div style={{marginTop: 20}}>
                      <DatePicker
                        value={newEndDate ? dayjs(newEndDate) : dayjs()}
                        onChange={(v) => {
                          setNewEndDate(v);
                        }}
                        style={{width: '100%'}}
                        format='YYYY-MM-DD'
                      />
                      <div style={{marginTop: 5}}>
                        <Button
                          type='primary'
                          onClick={updateEndDate}
                          disabled={saving}
                        >
                          Cambiar
                        </Button>
                        <Button
                          type='link'
                          style={{color: '#f30'}}
                          onClick={() => setShowChangeEndDate(false)}
                          disabled={saving}
                        >
                          Cerrar
                        </Button>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td width='25%'>Lugar de entrega</td>
                <td>
                  {pointNamesByType[subscription.data?.shipping_point]} -{' '}
                  {subscription.user?.data?.grin4uinfo
                    ? // eslint-disable-next-line prefer-template
                      `${subscription.user?.data?.grin4uinfo[subscription.data?.shipping_point + '_address']}, ${subscription.user?.data?.grin4uinfo[subscription.data?.shipping_point + '_district']}`
                    : '-'}
                </td>
              </tr>
            </table>
            <br />
            <br />
            <Tabs>
              <TabPane tab={<>Pagos</>} key='renewals'>
                {subscription.renewals?.map((x) => (
                  <RenewalItem
                    renewal={x}
                    subscription={subscription}
                    user={user}
                    onUpdate={onUpdateRenewal}
                  />
                ))}
              </TabPane>
              <TabPane
                tab={
                  <>
                    Items{' '}
                    <Badge
                      showZero
                      count={assignedItems.length || 0}
                      color='var(--mainColor)'
                    />
                  </>
                }
                key='items'
              >
                <SubscriptionItemsTable
                  items={subscription.items}
                  onAction={onItemAction}
                  manageItem={user?.can?.operations_grin4u}
                />
                <div>
                  {!showAddItemForm &&
                    !['FINISHED', 'VOID'].includes(subscription.status) && (
                      <Button
                        type='secondary'
                        style={{marginTop: 10}}
                        onClick={() => {
                          openAddItemForm();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{marginRight: 5}}
                        />
                        Agregar Item...
                      </Button>
                    )}
                </div>
                {showAddItemForm && (
                  <div style={{marginTop: 10}}>
                    <Space direction='horizontal'>
                      <Input
                        onChange={(e) => setQRNewItem(e.target.value)}
                        value={qRNewItem}
                        placholder='QR...'
                      />
                      <Button disabled={!qRNewItem} onClick={addItem}>
                        Agregar
                      </Button>
                    </Space>
                  </div>
                )}
              </TabPane>
              <TabPane
                tab={
                  <>
                    Documentos{' '}
                    <Badge
                      showZero
                      count={subscription?.data.documents?.length || 0}
                      color='var(--mainColor)'
                    />
                  </>
                }
                key='documents'
              >
                <SubscriptionDocumentsTable
                  documents={subscription.data.documents}
                  subscriptionId={subscription.id}
                  onChangeDocuments={(newSubscription) =>
                    setSubscription((prev) => ({
                      ...prev,
                      data: newSubscription.data,
                    }))
                  }
                />
              </TabPane>
              <TabPane tab={<>Historial</>} key='history'>
                <EventsList events={subscription.events} showItem />
              </TabPane>
            </Tabs>
          </div>
          <div>
            <div className={stylesLayout.sectionTitle}>
              Notas de la suscripción
            </div>
            <NotesListModule
              predefinedNotes={['Cliente contactado', 'Cliente no responde']}
              itemType='subscription'
              itemId={subscription.id}
              user={user}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SubscriptionModule.propTypes = {
  user: PropTypes.object.isRequired,
  selectedFleet: PropTypes.object.isRequired,
};
export default SubscriptionModule;
